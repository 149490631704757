import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { util as sdkUtil } from '../../util/sdkLoader';

import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchAllMerchantApi } from '../../util/api';
import { getConfigData } from '../EditListingPage/EditListingPage.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 9;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const FETCH_USER_REQUEST = 'app/SearchPage/FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'app/SearchPage/FETCH_USER_SUCCESS';
export const FETCH_MERCHANT_SUCCESS = 'app/SearchPage/FETCH_MERCHANT_SUCCESS';
export const FETCH_USER_ERROR = 'app/SearchPage/SFETCH_USER_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const SEARCH_LISTINGS_CUSTOM_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_CUSTOM_SUCCESS';

export const SEARCH_FILTER_REQUEST = 'app/SearchPage/SEARCH_FILTER_REQUEST';
export const SEARCH_FILTER_SUCCESS = 'app/SearchPage/SEARCH_FILTER_SUCCESS';
export const SEARCH_FILTER_ERROR = 'app/SearchPage/SEARCH_FILTER_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  fetchUserInProgress: false,
  fetchUserError: null,
  currentMerchantResultIds: [],
  customPageResultIds: [],
  subCatergoriesOptions: [
    { option: 'Jewelry/Accessories', value: 'Jewelry/Accessories', label: 'Jewelry/Accessories', parentId: 'Products' },
    { option: 'Clothing', value: 'Clothing', label: 'Clothing', parentId: 'Products' },
    { option: 'Footwear', value: 'Footwear', label: 'Footwear', parentId: 'Products' },
    { option: 'Health/Beauty', value: 'Health/Beauty', label: 'Health/Beauty', parentId: 'Products' },
    { option: 'Pet Supplies', value: 'Pet Supplies', label: 'Pet Supplies', parentId: 'Products' },
    { option: 'Home/Decor', value: 'Home/Decor', label: 'Home/Decor', parentId: 'Products' },
    { option: 'Books/Music/Instruments', value: 'Books/Music/Instruments', label: 'Books/Music/Instruments', parentId: 'Products' },
    { option: 'Games/Toys/Hobbies', value: 'Games/Toys/Hobbies', label: 'Games/Toys/Hobbies', parentId: 'Products' },
    { option: 'Grocery/Pharmacy', value: 'Grocery/Pharmacy', label: 'Grocery/Pharmacy', parentId: 'Products' },
    { option: 'Sporting Goods', value: 'Sporting Goods', label: 'Sporting Goods', parentId: 'Products' },
    { option: 'Hardware/Garden', value: 'Hardware/Garden', label: 'Hardware/Garden', parentId: 'Products' },
    { option: 'Electronics/Phone', value: 'Electronics/Phone', label: 'Electronics/Phone', parentId: 'Products' },
    { option: 'Local Artisans', value: 'Local Artisans', label: 'Local Artisans', parentId: 'Products' },
    { option: 'Auto', value: 'Auto', label: 'Auto', parentId: 'Products' },


    
    { option: 'Home Services', value: 'Home Services', label: 'Home Services', parentId: 'Services' },
    { option: 'Personal Services', value: 'Personal Services', label: 'Personal Services', parentId: 'Services' },
    { option: 'Pet Services', value: ' Pet Services', label: ' Pet Services', parentId: 'Services' },
    { option: 'Professional Services', value: 'Professional Services', label: 'Professional Services', parentId: 'Services' },
    { option: 'Medical/Dental', value: 'Medical/Dental', label: 'Medical/Dental', parentId: 'Services' },
    { option: 'Auto', value: 'Auto', label: 'Auto', parentId: 'Services' },
    { option: 'Lessons/Tutoring', value: 'Lessons/Tutoring', label: 'Lessons/Tutoring', parentId: 'Services' },
    { option: 'Beauty', value: 'Beauty', label: 'Beauty', parentId: 'Services' },
    { option: 'Fitness/Sports', value: 'Fitness/Sports', label: 'Fitness/Sports', parentId: 'Services' },
    { option: 'Wellness/Nutrition', value: 'Wellness/Nutrition', label: 'Wellness/Nutrition', parentId: 'Services' },
    { option: 'Electronics/Phone', value: 'Electronics/Phone', label: 'Electronics/Phone', parentId: 'Services' },
    { option: 'Rentals', value: 'Rentals', label: 'Rentals', parentId: 'Services' },
    { option: 'Catering/Events', value: 'Catering/Events', label: 'Catering/Events', parentId: 'Services' },
    { option: 'Entertainment & Arts', value: 'Entertainment & Arts', label: 'Entertainment & Arts', parentId: 'Services' },
    { option: 'Dining', value: 'Dining', label: 'Dining', parentId: 'Food & Drinks' },

    
    { option: 'Arab-cuisine', value: 'Arab cuisine', label: 'Arab cuisine', parentId: 'shop-by-food_drink' },
    { option: 'French_cuisine', value: 'French cuisine', label: 'French cuisine', parentId: 'shop-by-food_drink' },
    { option: 'Italian_cuisine', value: 'Italian cuisine', label: 'Italian cuisine', parentId: 'shop-by-food_drink' },
    { option: 'Japanese-cuisine', value: 'Japanese Cuisine', label: 'Japanese Cuisine', parentId: 'shop-by-food_drink' },
    { option: 'Mexican_cuisine', value: 'Mexican cuisine', label: 'Mexican cuisine', parentId: 'shop-by-food_drink' },
    { option: 'Turkish_cuisine', value: 'Turkish cuisine', label: 'Turkish cuisine', parentId: 'shop-by-food_drink' },
    { option: 'American_cuisine', value: 'American cuisine', label: 'American cuisine', parentId: 'shop-by-food_drink' },
  ],
};

let listings = [];

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      listings = [];
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        currentPageResultUsers: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:

      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_LISTINGS_CUSTOM_SUCCESS:
      listings.push(payload.data.data);
      const mergedListings = [].concat.apply([], listings);
      const ids = mergedListings && mergedListings.map(l => l.id);

      let uniqueArr =
        ids &&
        ids.length &&
        ids.reduce((unique, o) => {
          if (!unique.some(obj => obj['_sdkType'] === o['_sdkType'] && obj.uuid === o.uuid)) {
            unique.push(o);
          }
          return unique;
        }, []);

      return {
        ...state,
        customPageResultIds: uniqueArr,
        searchInProgress: false,
        searchAllListingsInProgress: false,
      };

    case FETCH_USER_REQUEST:
      return {
        ...state,
        fetchUserInProgress: true,
        fetchUserError: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        currentMerchantResultIds: resultIds(payload.data),
        fetchUserInProgress: false,
      };
    case FETCH_USER_ERROR:
      console.error(payload);
      return { ...state, fetchUserInProgress: false, fetchUserError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case SEARCH_FILTER_REQUEST:
      return {
        ...state,
        searchFilterInProgress: true,
        searchMapListingIds: [],
        searchFilterError: null,
      };
    case SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        filterProducts: payload.data,
        searchFilterInProgress: false,
      };
    case SEARCH_FILTER_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchFilterInProgress: false, searchFilterError: payload };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => (
  {
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const fetchUserRequest = searchParams => ({
  type: FETCH_USER_REQUEST,
});

export const fetchUserSuccess = response => ({
  type: FETCH_USER_SUCCESS,
  payload: { data: response },
});
export const fetchMerchantsSuccess = response => ({
  type: FETCH_MERCHANT_SUCCESS,
  payload: { data: response },
});

export const fetchUserError = e => ({
  type: FETCH_USER_ERROR,
  error: true,
  payload: e,
});

export const searchListingsCustomSuccess = response => ({
  type: SEARCH_LISTINGS_CUSTOM_SUCCESS,
  payload: { data: response.data },
});

export const searchFilterRequest = filterProducts => ({
  type: SEARCH_FILTER_REQUEST,
  payload: { filterProducts },
});

export const searchFilterSuccess = response => ({
  type: SEARCH_FILTER_SUCCESS,
  payload: { data: response },
});

export const searchFilterError = e => ({
  type: SEARCH_FILTER_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (searchParams, config, params) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
          pub_listingType: listingTypes.map(l => l.listingType),
        }
      : {};
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
        ? getExclusiveEndDate(endRaw, searchTZ)
        : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;

    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
          start: getProlongedStart(startDate),
          end: getProlongedEnd(endDate),
          availability: 'time-partial',
          minDuration,
        }
      : {};
  };

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const merchantType = searchParams?.pub_category == 'has_any:shop-by-food&drink,shop-by-merchants';
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = merchantType
    ? sort === config.search.sortConfig.relevanceKey
      ? {}
      : { sort: 'pub_createdAtDate' }
    : sort === config.search.sortConfig.relevanceKey
    ? {}
    : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config.listing.listingTypes),
    perPage,
  };
  return sdk.listings
    .query({...params})
    .then(async response => {
      const { subCatergoriesOptions } = getState().SearchPage;
      const sanitizeConfig = { subCatergoriesOptions };
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsCustomSuccess(response));
      dispatch(searchListingsSuccess(response));

      const meta = response && response.data && response.data.meta;
      const page = meta && meta.page;
      const { totalPages = 0, totalItems = 90 } = meta;

      const queryNextListings = async (page, totalPages) => {
        const newPage = page + 1;
        const res = await sdk.listings.query({ ...params, page: newPage, minStock: 1 });
        dispatch(addMarketplaceEntities(res, sanitizeConfig));
        dispatch(searchListingsCustomSuccess(res));

        const { totalItems = 90 } = meta;
        if (totalItems > 90 && totalPages !== newPage) {
          queryNextListings(newPage, totalPages);
        }
      };

      if (totalItems > 90 && totalPages !== page) {
        await queryNextListings(page, totalPages);
      }

      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};


export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const getAllMerchats = () => async dispatch => {
  try {
    dispatch(fetchUserRequest());
    fetchAllMerchantApi({
      pub_role: 'merchant',
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchUserSuccess(response.data));
        return response;
      })
      .catch(e => dispatch(fetchUserError(storableError(e))));
  } catch (error) {
    return dispatch(fetchUserError(storableError(e)));
  }
};

export const loadData = (params, search, config) => dispatch => {
  const { type } = params;

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const isTabMerchants = type=="shop-by-merchants";
  const typeFood = "shop-by-food&drink"
  const  pub_category =  isTabMerchants ? `has_any:${typeFood},${type}` : type;
  

  return Promise.all([
    dispatch(
      searchListings(
        {
          // ...minStockMaybe,
          pub_category,
          ...rest,
          ...originMaybe,
          page,
          perPage: RESULT_PAGE_SIZE,
          include: ['author', 'images', 'currentStock'],
          'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
          'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
          'fields.image': [
            `variants.${variantPrefix}`,
            `variants.${variantPrefix}-2x`,
            'variants.default',
          ],
          ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
          ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
          'limit.images': 1,
        },
        config
      )
    ),
    dispatch(getConfigData()),
  ]);
};
